<template>
  <Teleport to="body">
    <Transition name="fade" appear>
      <div v-if="show" class="fixed inset-0 z-50 bg-black opacity-50"></div>
    </Transition>
    <Transition appear :name="'dialog'">
      <div v-if="show" class="fixed inset-0 z-50 flex overflow-auto md:p-8">
        <div class="flex h-full w-full flex-1 flex-col items-center md:h-fit md:flex-none">
          <div
            ref="modalRef"
            class="relative flex w-full flex-col rounded-2xl bg-white sm:w-auto sm:min-w-[600px]"
            :class="[variant === 'fullscreen' && 'flex-1 md:flex-none']"
            v-bind="$attrs"
          >
            <button
              v-if="showClose"
              class="absolute right-4 top-4 z-[1] md:right-6 md:top-6"
              type="button"
              @click="$emit('on-close', { outside: false })"
            >
              <Icon name="close" />
            </button>
            <div ref="contentRef" class="max-h-full overflow-auto p-4 md:overflow-visible md:p-10">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
export interface ModalOnCloseEvent {
  outside: boolean
}

export type ModalVariant = 'basic' | 'fullscreen'

interface ModalProps {
  show?: boolean
  showClose?: boolean
  variant?: ModalVariant
  contentClass?: string
}

const props = withDefaults(defineProps<ModalProps>(), {
  showClose: true,
  variant: 'basic'
})

defineOptions({
  inheritAttrs: false
})

const emit = defineEmits<{
  'on-close': [event: ModalOnCloseEvent]
}>()

const contentRef = ref<HTMLElement | null>(null)
const modalRef = ref<HTMLElement | null>(null)

const { bodyLock, bodyUnlock } = useBodyLock()

onClickOutside(modalRef, () => {
  emit('on-close', { outside: true })
})

watch(
  () => props.show,
  (isShow) => {
    if (isShow) {
      bodyLock()
    } else {
      bodyUnlock()
    }
  }
)

onUnmounted(() => {
  bodyUnlock()
})
</script>
